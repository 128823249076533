<template>
  <v-container fluid fill-height>
    <v-card v-if="active" width="450" class="pa-3 pl-8" elevation="8" hover @click="route"
     v-on="$listeners"
    v-bind="$attrs">
      <v-row no-gutters class="d-flex flex-row">
        <v-col
          class="d-flex align-center"
          lg="2"
          sm="12"
          cols="12"
        >
          <v-icon x-large color="primary">{{ icon }}</v-icon>
        </v-col>
        <v-col lg="10">
          <v-row no-gutters class="d-flex flex-column">
            <v-col lg="12" v-if="optional" class="textOrange--text">
              [ Optional ]</v-col
            >
            <v-col lg="10" class="primary--text d-flex justify-space-between">{{ title }}   
            <v-icon v-if="isChecked" x-large color="primary">{{ checkIcon }}</v-icon>  </v-col>
            <v-col lg="10" class="textDark--text body-2  font-weight-light">{{
              description
            }}</v-col>
          </v-row>
        </v-col>
        
      </v-row>
    </v-card>
    <v-card v-else :disabled="true" width="450" class="pa-3" elevation="8" hover>
      <v-row no-gutters class="d-flex flex-row">
        <v-col
          class="d-flex align-center justify-center"
          lg="2"
          sm="12"
          cols="12"
        >
          <v-icon x-large color="textDark">{{ icon }}</v-icon>
        </v-col>
        <v-col lg="10">
          <v-row no-gutters class="d-flex flex-column">
            <v-col lg="12" v-if="optional" class="textOrange--text">
              [ Optional ]</v-col
            >
            <v-col lg="10" class="textDark--text">{{ title }}</v-col>
            <v-col lg="10" class="textDark--text body-2  font-weight-light">{{
              description
            }}</v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "BzCard",
  props: ["title", "description", "icon", "active", "route", "optional", "isChecked"],
  
  data(){
    return{
      checkIcon: 'mdi-check'
    };
  }
};
</script>

<style></style>
