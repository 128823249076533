<template>
  <div>
    <admin-header v-if="selectedCourse && selectedTraining" :title="'Submission'" :links="bcLinks"></admin-header>

    <v-card width="90%" min-height="50vh" max-height="50vh" v-if="isSubmissionClose">
      <div
        class="d-flex flex-column flex-grow-1 justify-center align-center fill-height"
        style="height: 50vh;"
      >
        <v-icon size="50" color="primary">mdi-close-box</v-icon>
        <div class="mt-4 headline">Submission for <strong>{{ selectedCourse.name }}</strong> ends</div>
      </div>
    </v-card>    

    <v-card width="90%" min-height="50vh" max-height="50vh" v-else-if="didAlreadySubmitCourse">
      <div
        class="d-flex flex-column flex-grow-1 justify-center align-center fill-height"
        style="height: 50vh;"
      >
        <v-icon size="50" color="primary">mdi-thumb-up</v-icon>
        <div class="mt-4 headline">Application Submitted!</div>
      </div>
    </v-card>

    <div v-else>
      <div class="subtitle-1 font-weight-regular textDark--text mt-4">Submit all forms on the list below</div>
      <v-card
        outlined
        v-if="selectedCourse"
        width="80%"
        min-height="50vh"
        max-height="50vh"
        class="mt-4"
        style="overflow-y: auto;"
        :loading="loading || submitting"
        :disabled="loading"
      >
        <v-card-text>
          <v-card
            hover
            v-for="step in selectedCourse.submissionSteps"
            :key="step._id"
            class="mb-4"
            @click="stepClicked(step)"
          >
            <v-card-text>
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                  <v-icon size="32" color="primary">{{ step.fileType | stepIcon }}</v-icon>
                  <div class="ml-4">
                    <div class="subtitle-1 primary--text">{{ step.name }}</div>
                    <div class="subtitle-2 secondaryText--text">{{ step.description }}</div>
                    <v-chip v-if="step.isOptional" small color="success" dark class="mt-2">Optional</v-chip>
                    <v-chip v-else small color="textOrange" dark class="mt-2">Required</v-chip>
                  </div>
                </div>
                <div class="d-flex">
                  <v-icon v-if="stepSubmitted(step._id)" size="32" color="primary">mdi-check</v-icon>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </div>

    <v-dialog :persistent="saving" v-if="currentStep" v-model="stepTextInputDialog" width="30%">
      <v-card class="scroll">
        <div class="d-flex primary pa-6">
          <v-icon size="32" dark>mdi-format-size</v-icon>
          <div class="headline white--text ml-4">{{ currentStep.name }}</div>
        </div>
        <v-card-text>
          <div class="d-flex flex-column">
            <v-textarea
              v-model="stepTextInputValue"
              counter
              :rows="6"
              no-resize
              full-width
              placeholder="Write something here"
            ></v-textarea>
            <v-btn
              width="50%"
              class="mt-4 text-capitalize title font-weight-regular align-self-center white--text"
              large
              :loading="saving"
              :disabled="!stepTextInputValue"
              @click="submitStepTextInput"
              color="textOrange"
            >Done</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      :persistent="saving"
      v-if="currentStep"
      v-model="stepFileInputDialog"
      width="30%"
      max-width="40%"
    >
      <v-card class="scroll">
        <div class="d-flex primary pa-6">
          <v-icon size="32" dark>{{ currentStep.fileType | stepIcon }}</v-icon>
          <div class="headline white--text ml-4">{{ currentStep.name }}</div>
        </div>
        <v-card-text>
          <div class="d-flex flex-column">
            <div
              class="d-flex pa-4 align-center align-center flex-wrap"
              v-if="currentSubmittedStep"
            >
              <div
                class="d-flex mr-4 mb-4 transparent"
                v-for="file in currentSubmittedStep.files"
                :key="file._id"
              >
                <v-card hover @click="openUrl(file.url)">
                  <v-img
                    v-if="currentStep.fileType === 'Image'"
                    width="115"
                    height="115"
                    max-width="115"
                    max-height="115"
                    :aspect-ratio="1"
                    :src="file.url"
                    cover
                  ></v-img>
                  <v-tooltip bottom v-else-if="currentStep.fileType === 'Doc'">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" size="115">mdi-file-document</v-icon>
                    </template>
                    <span>{{ file.name }}</span>
                  </v-tooltip>
                </v-card>

                <v-btn
                  class="ma-0 pa-0 ml-n8 mt-n3"
                  text
                  @click="removeFileFromSubmissionStep(file)"
                >
                  <v-icon color="textDark">mdi-close-box</v-icon>
                </v-btn>
              </div>
            </div>
            <v-file-input
              v-model="stepFilesInput"
              chips
              class="px-4 py-8"
              multiple
              :accept="currentStep.fileType | fileAcceptFormat"
              label="Select one or more files"
            ></v-file-input>
            <v-btn
              width="50%"
              class="mt-4 text-capitalize title font-weight-regular align-self-center white--text"
              large
              :loading="saving"
              :disabled="!canSaveSubmittedStep && !stepFilesInput.length"
              @click="submitStepFiles"
              color="textOrange"
            >Done</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-footer
      v-if="selectedCourse && !didAlreadySubmitCourse"
      height="65"
      fixed
      class="d-flex justify-center"
    >
      <div class="d-flex flex-row">
        <div class="body-1 mr-12 my-2">Click on Submit Button to Submit your application</div>

        <v-dialog v-model="submitDialog" width="500">
          <template v-slot:activator="{ on }">
            <bz-button
              v-on="on"
              title="Submit"
              :loading="submitting"
              depressed
              icon="mdi-content-save"
              type="Submit"
              :disabled="!canSubmit || didAlreadySubmitCourse || isSubmissionClose"
              large
              width="300"
            >
              <v-divider vertical color="white" class="ml-4 mr-0" />
            </bz-button>
          </template>

          <v-card>
            <v-card-title class="headline primary white--text" primary-title>Course Submission</v-card-title>

            <v-card-text class="pt-4 body-1 textDark--text">
              Are you sure that you want to submit this course?<br />
              <p class="mt-2"><strong>Please note:</strong> this operation is undoable and you will not be able to resubmit this course again<br /></p>
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large class="text-capitalize mr-2" dark  color="secondaryText" @click="submitDialog = false">Cancel</v-btn>
              <v-btn large class="text-capitalize" dark color="textOrange" @click="submitCourseClicked">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-footer>
    <BzNotification v-model="notification"></BzNotification>
  </div>
</template>

<style scoped>
.scroll {
  overflow: hidden;
}
</style>

<script>
import BzButton from "@shared/components/BzSubmitButton";
import BzNotification from "@/shared/components/BzNotification";
import AdminHeader from "@/beez-admin/components/AdminHeader";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BzCards from "@/beez-modules/beez-user/modules/elevation/submission/components/BzCards.vue";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import remove from "lodash.remove";
import difference from "lodash.difference";
import moment from "moment";

export default {
  name: "submission",
  components: {
    BzCards,
    BzButton,
    AdminHeader,
    BzNotification
  },

  data() {
    return {
      loading: false,
      saving: false,
      submitting: false,
      submitted: false,
      stepTextInputDialog: false,
      stepTextInputValue: "",
      stepFileInputDialog: false,
      stepFilesInput: [],
      stepFileEditDialog: false,
      stepFileEditDialogLoading: false,
      currentStep: null,
      currentSubmittedStep: null,
      selectedsubmittedStep: null,
      canSaveSubmittedStep: false,
      submitDialog: false
    };
  },
  async created() {
    this.refresh();
  },

  filters: {
    stepIcon(fileType) {
      switch (fileType) {
        case "Text":
          return "mdi-format-size";
        case "Video":
          return "mdi-play-box-multiple";
        case "Image":
          return "mdi-image";
        default:
          return "mdi-text-box";
      }
    },
    fileAcceptFormat(fileType) {
      switch (fileType) {
        case "Image":
          return "image/*";
        case "Video":
          return "video/*";
        case "Doc":
          return ".doc,application/pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        default:
          return "*";
      }
    }
  },

  computed: {
    ...mapFields("user", ["submittedFiles"]),
    ...mapFields("userElevation", [
      "selectedCourse",
      "selectedTraining",
      "userSubmissions"
    ]),
    disableButton() {
      return this.NumberOfRequiredData === this.requiredSteps.length
        ? false
        : true;
    },
    canSubmit() {
      // get all required steps ids for this course
      const requiredStepsIds = this.selectedCourse.submissionSteps
        .filter(item => !item.isOptional)
        .map(item => item._id);
      const submissionStepsIds = this.userSubmissions.map(
        item => item.submissionStepId
      );

      // check if the current user submissions contains all required steps ids
      if (
        difference(
          requiredStepsIds,
          this.userSubmissions.map(item => item.submissionStepId)
        ).length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Indicates if submission deadline has reached or not 
     */
    isSubmissionClose(){

      if (!this.selectedCourse){
        return false;
      }

      // if course has no deadline then
      // we can always submit 
      if (!this.selectedCourse.endDate){
        return false;
      }

      

      // check if current CET time is after course deadline
      // if it is then we can block submission
      // check dates only 
      if (moment().startOf('day').isAfter(moment(this.selectedCourse.endDate).startOf('day'))){
        return true;
      }

      return false
    },
    didAlreadySubmitCourse() {
      const finished = this.userSubmissions.filter(item => {
        return item.status === "FINISHED";
      });

      return finished && finished.length ? true : false;
    },
    bcLinks() {
      return [
        {
          text: "Trainings",
          disabled: false,
          to: {
            name: "user-training"
          }
        },
        {
          text: this.selectedTraining.name,
          disabled: false,
          to: {
            name: "user-courses",
            query: {
              training: this.training,
              id: this.ti
            }
          }
        },
        {
          text: this.selectedCourse.name,
          disabled: false,
          to: {
            name: "user-course-info",
            params: {
              id: this.selectedCourse._id
            }
          }
        },
        {
          text: "Submission",
          disabled: true
        }
      ];
    }
  },

  methods: {
    ...mapActions("training", {
      getCourse: "getCourse",
      getCourses: "getCourses"
    }),
    ...mapActions("userElevation", {
      getCourse: "getCourse",
      getSubmissionsForSteps: "getSubmissionsForSteps",
      getTraining: "getTraining",
      createStepSubmission: "createStepSubmission",
      createStepFilesSubmission: "createStepFilesSubmission",
      getStepSubmissionFiles: "getStepSubmissionFiles",
      updateStepFilesSubmission: "updateStepFilesSubmission",
      updateStepSubmission: "updateStepSubmission",
      uploadFilesForSubmission: "uploadFilesForSubmission",
      completeSubmission: "completeSubmission"
    }),
    ...mapActions("admin", {
      uploadFile: "uploadFile",
      fetchFilesUploaded: "fetchFilesUploaded"
    }),
    ...mapActions("user", {
      getSubmissions: "getSubmissions"
    }),
    ...mapActions("user", {
      createSubmission: "createSubmission"
    }),

    stepSubmitted(stepId) {
      if (!this.userSubmissions || this.userSubmissions.length === 0) {
        return false;
      }

      const results = this.userSubmissions.filter(item => {
        return item.submissionStepId === stepId;
      });

      return results.length > 0 ? results[0] : false;
    },

    async refresh() {
      try {
        this.loading = true;

        // load course if needed
        if (
          !this.selectedCourse ||
          this.selectedCourse._id !== this.$route.params.courseId
        ) {
          await this.getCourse(this.$route.params.courseId);
        }

        // load training if needed
        if (
          !this.selectedTraining ||
          this.selectedTraining._id !== this.$route.params.trainingId
        ) {
          await this.getTraining(this.$route.params.trainingId);
        }

        if (this.isSubmissionClose){
          return;
        }

        // load user submissions from course steps
        // result will be stored in the state under userSubmissions
        await this.getSubmissionsForSteps(
          this.selectedCourse.submissionStepIds
        );
      } finally {
        this.loading = false;
      }

      // load submissions here
    },

    async stepClicked(step) {
      this.currentStep = step;

      // if step submitted we need to show it to the user
      // otherwise we need to allow the user to create it
      let submittedStep = this.stepSubmitted(step._id);

      // if step already submitted then it means that we're in edit mode
      if (submittedStep) {
        this.selectedsubmittedStep = submittedStep;
        // clone to detect changes
        this.currentSubmittedStep = cloneDeep(submittedStep);
        // download step files
        if (step.fileType === "Image" || step.fileType === "Doc") {
          // this.stepFileInputDialog = true;
          try {
            this.loading = true;
            this.stepFileEditDialogLoading = true;
            // download submitted step files
            this.currentSubmittedStep.files = await this.getStepSubmissionFiles(
              this.currentSubmittedStep.fileIds
            );
            this.stepFileInputDialog = true;
          } finally {
            this.stepFileEditDialogLoading = false;
            this.loading = false;
          }
        } else if (step.fileType === "Text") {
          this.stepTextInputValue = this.currentSubmittedStep.text;
          this.stepTextInputDialog = true;
        }
      } else {
        this.editStep(step);
      }
    },

    editStep(step) {
      this.currentStep = step;

      if (step.fileType === "Text") {
        this.stepTextInputDialog = true;
      } else {
        this.stepFileInputDialog = true;
      }
    },

    async submitStepTextInput() {
      try {
        this.saving = true;

        if (this.currentSubmittedStep) {
          this.currentSubmittedStep.text = this.stepTextInputValue;
          await this.updateStepSubmission(this.currentSubmittedStep);
        } else {
          await this.createStepSubmission({
            submissionStepId: this.currentStep._id,
            text: this.stepTextInputValue
          });
        }

        this.stepTextInputDialog = false;
      } finally {
        this.saving = false;
      }
    },

    openUrl(url) {
      window.open(url, "_blank");
    },

    removeFileFromSubmissionStep(file) {
      const clonedSubmittedStep = cloneDeep(this.currentSubmittedStep);
      remove(clonedSubmittedStep.fileIds, item => item === file._id);
      remove(clonedSubmittedStep.files, item => item._id === file._id);

      this.currentSubmittedStep = clonedSubmittedStep;
    },

    async submitStepFiles() {
      try {
        this.saving = true;
        if (this.currentSubmittedStep) {
          // if the user selected more files then we first need to upload them
          if (this.stepFilesInput && this.stepFilesInput.length) {
            const files = await this.uploadFilesForSubmission(
              this.stepFilesInput
            );
            // add the file ids to the step submission object before update it
            files.forEach(file => {
              this.currentSubmittedStep.fileIds.push(file._id);
            });
          }

          // update the step submission object
          await this.updateStepSubmission(this.currentSubmittedStep);
        } else {
          await this.createStepFilesSubmission({
            submissionStepId: this.currentStep._id,
            filesData: this.stepFilesInput
          });
        }

        this.stepFileInputDialog = false;
      } finally {
        this.saving = false;
      }
    },
    async submitCourseClicked() {

      this.submitDialog = false;

      try {
        this.submitting = true;
        await this.completeSubmission(this.selectedCourse._id);
      } finally {
        this.submitting = false;
      }
    }
  },
  watch: {
    stepTextInputDialog(value) {
      if (!value) {
        this.stepTextInputValue = "";
        this.currentSubmittedStep = null;
      }
    },
    stepFileInputDialog(value) {
      if (!value) {
        this.stepFilesInput = [];
        this.currentSubmittedStep = null;
      }
    },
    currentSubmittedStep: {
      deep: true,
      handler(value) {
        this.canSaveSubmittedStep = !isEqual(value, this.selectedsubmittedStep);
      }
    },
    userSubmissions: {
      immediate: true,
      handler(value) {}
    }
  }
};
</script>

<style scoped>
.file-input {
  width: 90%;
}
</style>
